<template>
  <b-card>
    <b-card-title class="font-weight-bolder">
      <b-icon-exclamation-circle
                    variant="danger"
                    class="mr-50"
                ></b-icon-exclamation-circle>
      故障统计
    </b-card-title>

    <b-row v-if="topInfo">
      <b-col cols="3" class="text-center">
        <b-avatar rounded size="48" variant="light-danger">
          <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
        </b-avatar>
        <h5 class="font-weight-bolder mt-1">即时诊断</h5>
        <h3 class="my-0">
          <span class="text-danger" style="font-size: 17px">{{ numberFormat(topInfo.immed_total)[0] }} <span
              style="font-size: 10px; font-weight: bold">{{
              numberFormat(topInfo.immed_total)[1]
            }}</span></span>
        </h3>
      </b-col>

      <b-col cols="3" class="text-center">
        <b-avatar rounded size="48" variant="light-primary">
          <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
        </b-avatar>
        <h5 class="font-weight-bolder mt-1">即时故障{{topInfo.immed_fault_total}}</h5>
        <h3 class="my-0">
          <span class="text-primary" style="font-size: 17px">{{ numberFormat(topInfo.immed_fault_total)[0] }} <span
              style="font-size: 10px; font-weight: bold">{{ numberFormat(topInfo.immed_fault_total)[1] }}</span></span>
        </h3>
      </b-col>

      <b-col cols="3" class="text-center">
        <b-avatar rounded size="48" variant="light-warning">
          <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
        </b-avatar>
        <h5 class="font-weight-bolder mt-1">延时诊断</h5>
        <h3 class="my-0">
          <span class="text-warning" style="font-size: 17px">{{ numberFormat(topInfo.delay_total)[0] }} <span
              style="font-size: 10px; font-weight: bold">{{ numberFormat(topInfo.delay_total)[1] }}</span></span>
        </h3>
      </b-col>

      <b-col cols="3" class="text-center">
        <b-avatar rounded size="48" variant="light-success">
          <b-icon-hdd-rack-fill scale="2"></b-icon-hdd-rack-fill>
        </b-avatar>
        <h5 class="font-weight-bolder mt-1">延时故障</h5>
        <h3 class="my-0">
          <span class="text-success" style="font-size: 17px">{{ numberFormat(topInfo.delay_fault_total)[0] }} <span
              style="font-size: 10px; font-weight: bold">{{ numberFormat(topInfo.delay_fault_total)[1] }}</span></span>
        </h3>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  computed,
  onMounted,
  reactive,
  watch,
  toRefs,
} from "@vue/composition-api";

export default {
  name: "Diagnosis",
  props: {
    topInfos: [Object],
  },
  setup(props) {
    const event = reactive({
      topInfo: computed(() => {
        console.log(props.topInfos);
        return props.topInfos;
      }),
    });

    const numberFormat = (value) => {
      if (!value) return [0, ''];
      let unit = '';
      let k = 10000,
          sizes = ['', '万', '亿', '万亿'],
          i;
      if (value < k) {
        value = value;
      } else {
        i = Math.floor(Math.log(value) / Math.log(k));
        value = ((value / Math.pow(k, i))).toFixed(2);
        unit = sizes[i];
      }
      return [value, unit];
    }

    return {
      ...toRefs(event),
      numberFormat
    };
  },
};
</script>
<style lang="scss" scoped></style>
