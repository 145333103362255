<template>
    <b-card>
        <b-card-title class="font-weight-bolder">
            <b-icon-gear-wide-connected
                    variant="primary"
                    class="mr-50"
                ></b-icon-gear-wide-connected>
             故障设备 </b-card-title>

        <b-card-text
            id="project-detail-operation-scroll-notice1"
            style="height: 300px; overflow-y: hidden; overflow-x: hidden"
            @mouseenter="
                scroll_stop('#project-detail-operation-scroll-notice1')
            "
            @mouseleave="
                scroll_active('#project-detail-operation-scroll-notice1')
            "
        >
            <app-timeline>
                <app-timeline-item
                    v-for="(item, index) in store.state.diagnosis.notices"
                    :key="'agent_' + index"
                    :time="item.create_date"
                    :title="item.eqname"
                    :variant="
                        item.degree === '重要'
                            ? 'danger'
                            : item.degree === '一般'
                            ? 'info'
                            : 'warning'
                    "
                >
                </app-timeline-item>
            </app-timeline>
        </b-card-text>
    </b-card>
</template>
<script>
import { onMounted, ref, reactive, toRefs } from "@vue/composition-api";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { scroll_active, scroll_stop } from "@/libs/utils/autoScroll";
import store from '@/store'

export default {
    name: "DiagnosticEvent",
    components: {
        AppTimeline,
        AppTimelineItem,
    },
    setup() {
        const event = reactive({
            projectOperation: [
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "重要",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "一般",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "警告",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "警告",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "重要",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "一般",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "警告",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "一般",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "重要",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "一般",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "重要",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "警告",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "重要",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "一般",
                },
                {
                    time: "2023-01-01",
                    Description: "Default Description 1",
                    Degree: "重要",
                },
                {
                    time: "2023-02-01",
                    Description: "Default Description 2",
                    Degree: "警告",
                },
            ],
        });

        onMounted(() => {
            scroll_active("#project-detail-operation-scroll-notice1");
        });

        return {
            ...toRefs(event),
            scroll_active,
            scroll_stop,
          store
        };
    },
};
</script>
<style lang="scss" scoped>
.timeline-item {
    padding-left: 1.5rem !important;

    div > h6 {
        font-size: 0.9rem !important;
    }
}
</style>
