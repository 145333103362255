<template>
  <b-card title="故障诊断">
    <b-row>
      <b-col cols="3">
        <div class="mb-1">
          <span class="font-title">故障分类</span>
          <div id="fault-category-pie1" style="height: 11.5rem"></div>
        </div>

        <div>
          <span class="font-title">子系统设计</span>
          <div id="fault-category-pie2" style="height: 11.5rem"></div>
        </div>

        <div>
          <span class="font-title">位置分裂统计</span>
          <div id="fault-category-pie3" style="height: 11.5rem"></div>
        </div>
      </b-col>
      <b-col cols="3">
        <div class="mb-1">
          <span class="font-title">设备统计</span>
          <div id="fault-category-pie4" style="height: 11.5rem"></div>
        </div>

        <div>
          <span class="font-title">程度统计</span>
          <div id="fault-category-pie5" style="height: 11.5rem"></div>
        </div>

        <div>
          <span class="font-title">类型分类</span>
          <div id="fault-category-pie6" style="height: 11.5rem"></div>
        </div>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col cols="12">
            <div>
              <span class="font-title">逐月故障分布图</span>
              <div id="fault-distribution-column" style="height: 6.5rem;margin-top:5px"></div>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-top:10px ">
          <b-col cols="6">
            <div>
              <span class="font-title">逐日故障分布图</span>
              <div id="fault-distribution-column2" style="height: 6.5rem;margin-top:5px"></div>
            </div>
          </b-col>
          <b-col cols="6">
            <div>
              <span class="font-title">逐时故障分布图</span>
              <div id="fault-distribution-column3" style="height: 6.5rem;margin-top:5px"></div>
            </div>
          </b-col>
        </b-row>
        <b-row style="margin-top:10px">
            <b-col cols="6">
                <div>
                    <span class="font-title">故障事件</span>
                    <b-img src="/images/gundong.png" style="width: 26rem;height: 21rem"></b-img>
                </div>
            </b-col>
            <b-col cols="6">
                <div>
                    <span class="font-title">诊断事件</span>
                    <b-img src="/images/gundong.png" style="width: 26rem;height: 21rem"></b-img>
                </div>
            </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { onMounted, ref } from "@vue/composition-api";
import { $themeColors } from "@themeConfig";
import { Pie, Column } from "@antv/g2plot";

export default {
  name: "FaultDiagnosis",
  setup() {
    const data1 = [
      { type: "分类一", value: 27 },
      { type: "分类二", value: 25 },
      { type: "分类三", value: 20 },
      { type: "分类四", value: 18 },
      { type: "分类五", value: 10 },
    ];

    const pieData = [
      { type: "暖通", value: 13 },
      { type: "能源", value: 24 },
      { type: "电气", value: 46 },
      { type: "给排水", value: 56 },
    ]

    const pieData1 = [
      { type: "重要", value: 64 },
      { type: "一般", value: 32 },
      { type: "提醒", value: 6 },
    ]



    const data2 = [
      {
        type: "2023-01",
        sales: 38,
      },
      {
        type: "2023-02",
        sales: 52,
      },
      {
        type: "2023-03",
        sales: 61,
      },
      {
        type: "2023-04",
        sales: 145,
      },
      {
        type: "2023-05",
        sales: 48,
      },
      {
        type: "2023-06",
        sales: 38,
      },
      {
        type: "2023-07",
        sales: 38,
      },
      {
        type: "2023-08",
        sales: 38,
      },
    ];

    const pieChartInit = async () => {
      // 初始化第一个图表
      await initPieChart("fault-category-pie1", data1);

      // 可以在此添加其他异步操作，确保正确的初始化顺序

      // 初始化第二个图表
      // const data2 = [...]; // 准备第二个图表的数据
      await initPieChart("fault-category-pie2", pieData);
      await initPieChart("fault-category-pie3", data1);
      await initPieChart("fault-category-pie4", data1);
      await initPieChart("fault-category-pie5", pieData1);
      await initPieChart("fault-category-pie6", data1);
    };

    const initPieChart = async (containerId, data) => {
      const piePlot = new Pie(containerId, {
        theme: {
          colors10: Object.values($themeColors),
        },
        appendPadding: 10,
        data: data,
        angleField: "value",
        colorField: "type",
        radius: 1,
        innerRadius: 0.6,
        legend: {
          position: "left",
        },
        label: {
          type: "inner",
          offset: "-50%",
          content: "{value}",
          style: {
            textAlign: "center",
            fontSize: 14,
          },
        },
        interactions: [
          { type: "element-selected" },
          { type: "element-active" },
        ],
        statistic: {
          title: {
            formatter: () => "合计",
            offsetY: -8,
            style: {
              fontSize: "0.9rem",
              color: "",
            },
          },
          content: {
            offsetY: -4,
            style: {
              fontSize: "1.286rem",
              fontWeight: "normal",
              color: "",
            },
          },
        },
        interactions: [
          {
            type: "element-selected",
          },
          {
            type: "element-highlight",
          },
          {
            type: "pie-statistic-active",
            cfg: {
              start: [
                {
                  trigger: "element:mouseenter",
                  action: "pie-statistic:change",
                },
                {
                  trigger: "legend-item:mouseenter",
                  action: "pie-statistic:change",
                },
              ],
              end: [
                {
                  trigger: "element:mouseleave",
                  action: "pie-statistic:reset",
                },
                {
                  trigger: "legend-item:mouseleave",
                  action: "pie-statistic:reset",
                },
              ],
            },
          },
        ],
      });
      piePlot.render();
    };

    const columnChartInit = async() =>{
        await initColumnChart("fault-distribution-column",data2)
        await initColumnChart("fault-distribution-column2",data2)
        await initColumnChart("fault-distribution-column3",data2)
    }

    const initColumnChart = async(containerId,data) => {
      const columnPlot = new Column(containerId, {
        data: data,
        xField: "type",
        yField: "sales",
        label: {
          // 可手动配置 label 数据标签位置
          position: "middle", // 'top', 'bottom', 'middle',
          // 配置样式
          style: {
            fill: "#FFFFFF",
            opacity: 0.6,
          },
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        meta: {
          type: {
            alias: "类别",
          },
          sales: {
            alias: "销售额",
          },
        },
      });

      columnPlot.render();
    };

    onMounted(() => {
      pieChartInit();
      columnChartInit();
    });
  },
};
</script>
<style lang="scss" scoped></style>
