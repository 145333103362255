<template>
  <b-card class="bg-primary">
    <b-card-title class="font-weight-bolder text-light">
      总健康度指标
    </b-card-title>
    <b-row>
      <b-col cols="7">
        <div id="total-health-indicators" style="height: 8rem"></div>
      </b-col>
      <b-col cols="5" class="d-flex align-items-center" v-if="topInfo && topInfo.health && topInfo.health.degree">
        <div class="text-center">
          <b-icon-emoji-smile-fill
            variant="light"
            scale="2"
          ></b-icon-emoji-smile-fill>
          <h1 class="font-title text-light mt-1">{{ topInfo.health.degree }}</h1>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { Liquid } from "@antv/g2plot";
import { $themeColors } from "@themeConfig";
import { computed, onMounted, reactive, watch,  toRefs,} from "@vue/composition-api";

export default {
  name: "TotalHealth",
  props: {
    topInfos: [Object],
  },
  setup(props) {
    const event = reactive({
      topInfo: computed(() => {
        console.log(props.topInfos);
        return props.topInfos;
      }),
    });

    watch(
      () => event.topInfo,
      (topInfo) => {
        if(topInfo){
            console.log("健康指标数据发生变化",event.topInfo)
            liquidChartInit(event.topInfo.health)
        }
      }
    );

    const liquidChartInit = (data) => {
        console.log("更新健康度",data)
      const liquidPlot = new Liquid(
        document.getElementById("total-health-indicators"),
        {
          theme: {
            colors10: Object.values($themeColors),
          },
          color: () => {
            return $themeColors.light;
          },
          percent: data.score * 0.01,
          radius: 1,
          statistic: {
            title: {
              formatter: () => "健康度",
              style: ({ percent }) => ({
                fontSize: "1.5rem",
                fill: percent >= 0.65 ? "#1890ff" : "white",
              }),
            },
            content: {
              style: ({ percent }) => ({
                fontSize: "2rem",
                fill: percent >= 0.5 ? "#1890ff" : "white",
              }),
              customHtml: (container, view, { percent }) => {
                const text = `${(percent * 10).toFixed(1)}`;
                return `<div>${text}</div>`;
              },
            },
          },
        }
      );
      liquidPlot.render();
    };

    onMounted(() => {
      console.log("健康度数据", event.topInfo);
    //   liquidChartInit();
    });

    return {
      ...toRefs(event),
    };
  },
};
</script>
<style scoped lang="scss">
.text-center {
  font-size: 20px;
}
</style>
